import React, { useCallback } from 'react'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { TicketOption } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTickets/TicketOption'

interface GroupTicketsV2OptionsProps {
  groupTicketOptions?: GroupTicketOption[]
  ticketPrice: number
  groupTicketOption: GroupTicketOption
  setGroupTicketOption: React.Dispatch<React.SetStateAction<GroupTicketOption>>
}

export const GroupTicketsOptions: React.FC<GroupTicketsV2OptionsProps> = ({
  groupTicketOptions,
  ticketPrice,
  groupTicketOption,
  setGroupTicketOption,
}) => {
  const formatSubtitle = useCallback((subTitle: string) => {
    const words = subTitle.split(',')
    words[1] = '\u2192'
    return words.join('')
  }, [])

  return (
    <div className="mb-6 mt-4 flex justify-center">
      <form className="flex w-full flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
        {groupTicketOptions &&
          groupTicketOptions.map((info) => {
            const { title, subTitle, totalTicketCount } = info
            const isSelected = groupTicketOption.totalTicketCount === info.totalTicketCount
            if (!title || !subTitle || !totalTicketCount) return null
            return (
              <TicketOption
                key={title}
                onClick={() => setGroupTicketOption(info)}
                title={title}
                subTitle={formatSubtitle(subTitle)}
                ticketPrice={ticketPrice}
                ticketQuantity={totalTicketCount}
                selected={isSelected}
                paidQuantity={totalTicketCount / 2}
              />
            )
          })}
      </form>
    </div>
  )
}
