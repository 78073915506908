import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Button } from '@/atoms/Button'
import { FlexColumn } from '@/atoms/FlexContainers'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { Text } from '@/atoms/Text'
import { useShopifyCart } from '@/services/Shopify/hooks'
import { useSafeTrack } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { logger } from '@/utils/logging'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useShopifyUserAttributes } from '@/views/PreSales/hooks/useShopifyUserAttributes'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { TicketOption } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTickets/TicketOption'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'

const GROUP_DISCOUNT_RATE = 0.5
const OPTIONS = [10, 20, 50, 100, 200]

interface Props {
  setModalType: React.Dispatch<React.SetStateAction<GroupTicketsModalTypes>>
  ticketPrice: number
  theatricalSlug: string
  groupTicketProductVariantId: string
}

const DISCOUNT_CODES = {
  10: 'GROUP10', // Buy 8 get 2 free
  20: 'GROUP20', // Buy 15 get 5 free
  50: 'GROUP50', // Buy 30 get 20 free
  100: 'GROUPTICKETSDISCOUNT', // Buy 50 get 50 free
  200: 'GROUPTICKETSDISCOUNT', // Buy 100 get 100 free
}

export const ShopifyGroupTickets: React.FC<Props> = ({
  setModalType,
  ticketPrice,
  groupTicketProductVariantId,
  theatricalSlug,
}) => {
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()
  const { trackProductAdded, trackProductViewed } = useEcommerceEvents()
  const [selectedQuantity, setSelectedQuantity] = useState(OPTIONS[3])
  const [isProcessing, setIsProcessing] = useState(false)
  const { groupTicketExperiment } = useTicketsContext()

  const getDiscountCode = (quantity: number): string | null => {
    if (!groupTicketExperiment) return 'GROUPTICKETSDISCOUNT'
    return DISCOUNT_CODES[quantity as keyof typeof DISCOUNT_CODES] || null
  }

  const getDiscountInfo = (quantity: number) => {
    if (!groupTicketExperiment) {
      return {
        paidQuantity: quantity / 2,
        freeQuantity: quantity / 2,
        totalQuantity: quantity,
      }
    }

    switch (quantity) {
      case 10:
        return { paidQuantity: 8, freeQuantity: 2, totalQuantity: 10 }
      case 20:
        return { paidQuantity: 15, freeQuantity: 5, totalQuantity: 20 }
      case 50:
        return { paidQuantity: 30, freeQuantity: 20, totalQuantity: 50 }
      case 100:
        return { paidQuantity: 50, freeQuantity: 50, totalQuantity: 100 }
      case 200:
        return { paidQuantity: 100, freeQuantity: 100, totalQuantity: 200 }
      default:
        return null
    }
  }

  const attributes = useShopifyUserAttributes({
    funnel: 'theatrical-group-tickets',
    category: 'theatrical group tickets',
    project_slug: theatricalSlug,
  })

  const { createCart, applyDiscountCode } = useShopifyCart()

  useEffect(() => {
    track('Group Tickets Options Viewed')
  }, [track])

  const handleCtaClick = async (quantity: number) => {
    try {
      setIsProcessing(true)

      const { cart } = await createCart({
        lines: [{ quantity, merchandiseId: `gid://shopify/ProductVariant/${groupTicketProductVariantId}` }],
        attributes,
      })

      if (!cart?.id) return

      const discountCode = getDiscountCode(quantity)
      const finalCart = discountCode
        ? (await applyDiscountCode({ cartId: cart.id, discountCodes: [discountCode] })).cart
        : cart

      if (!finalCart?.checkoutUrl) return

      track('Group Tickets Checkout Started')

      trackProductAdded({
        category: 'group tickets',
        funnel: 'theatrical',
        name: `Group Ticket ${theatricalSlug}`,
        price: Number((ticketPrice * GROUP_DISCOUNT_RATE).toFixed(2)),
        currency: 'USD',
        quantity: selectedQuantity,
        product_id: `group-tickets-${theatricalSlug}`,
        sku: `group-tickets-${theatricalSlug}`,
        url: finalCart.checkoutUrl,
        projectSlug: theatricalSlug,
      })

      window.open(finalCart.checkoutUrl, '_blank')
    } catch (err) {
      logger().error('Failed to create group ticket checkout', err)
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <InView
      triggerOnce
      onChange={(inView) => {
        if (!inView) return

        trackProductViewed({
          category: 'group tickets',
          funnel: 'theatrical',
          name: `Group Ticket ${theatricalSlug}`,
          price: Number((ticketPrice * GROUP_DISCOUNT_RATE).toFixed(2)),
          currency: 'USD',
          quantity: selectedQuantity,
          product_id: `group-tickets-${theatricalSlug}`,
          sku: `group-tickets-${theatricalSlug}`,
          url: '',
          projectSlug: theatricalSlug,
          value: selectedQuantity * ticketPrice * GROUP_DISCOUNT_RATE,
        })
      }}
    >
      <Text className="photon-heading-xs md:photon-heading-sm block text-center" color="core-gray-950" weight="bold">
        {t('flexGroupTickets', 'Flex Group Tickets')}
      </Text>
      <Text
        color="core-gray-700"
        weight="medium"
        className="photon-paragraph-sm md:photon-paragraph-md mt-1 block pb-6 text-center"
      >
        <Translate t={t} i18nKey="youCanUseTheseTicketsOnAnySoundOfFreedomV6">
          Tickets only available at Angel Partner Theaters. Ticket prices estimated at $15. Additional charges may
          apply.{' '}
          <Button
            onClick={() => setModalType('faq')}
            className="inline font-semibold text-core-gray-950 underline"
            variant="plain"
          >
            Learn More
          </Button>
        </Translate>
      </Text>
      <div className="mb-6 mt-4 flex justify-center">
        <form className="flex w-full flex-col gap-2" onSubmit={(e) => e.preventDefault()}>
          {groupTicketProductVariantId &&
            OPTIONS.map((quantity) => {
              const discountInfo = getDiscountInfo(quantity)
              if (!discountInfo) return null

              const title = t('groupTicketTitle', '{{quantity}} Tickets', { quantity })

              const subTitle = t('groupTicketSubtitleExperiment', 'Buy {{paidQuantity}} → Get {{freeQuantity}} Free', {
                paidQuantity: groupTicketExperiment ? discountInfo.paidQuantity : quantity / 2,
                freeQuantity: groupTicketExperiment ? discountInfo.freeQuantity : quantity / 2,
              })

              const isSelected = selectedQuantity === quantity
              return (
                <TicketOption
                  key={quantity}
                  onClick={() => setSelectedQuantity(quantity)}
                  title={title}
                  subTitle={subTitle}
                  ticketPrice={ticketPrice}
                  ticketQuantity={quantity}
                  selected={isSelected}
                  paidQuantity={groupTicketExperiment ? discountInfo.paidQuantity : quantity / 2}
                />
              )
            })}
        </form>
      </div>
      <FlexColumn className="items-center gap-6">
        <Button
          onClick={() => handleCtaClick(selectedQuantity)}
          className="mx-auto flex w-full max-w-[380px] items-center justify-center gap-1 rounded-[14px] px-7 py-[17px] md:w-fit"
          variant="black"
          disabled={isProcessing}
        >
          {isProcessing && <LoadingSpinnerIcon color="black" size={16} />}
          {t('checkoutGroupTickets', 'Checkout')}
        </Button>
      </FlexColumn>
    </InView>
  )
}
