import axios from 'axios'
import { setCookie } from 'cookies-next'
import { E164Number } from 'libphonenumber-js'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { isProductionEnvironment } from '@/utils/environment-utils'

const baseUrl = isProductionEnvironment() ? 'https://auth.angel.com' : 'https://auth.stg.angel.com'

export const doWhatsAppSignUp = (locale: string, phone: E164Number | undefined, trackingCallback: () => void) => {
  return axios
    .post(
      `${baseUrl}/auth/signup`,
      {
        phone,
        provider: 'whatsapp',
        source: 'angel-web',
      },
      { headers: { 'x-accept-language': locale } },
    )
    .catch(() => {
      return whatsAppResendCode(phone, locale, trackingCallback)
    })
}

export const validateWhatsAppVerificationCode = (
  phone: E164Number | undefined,
  otp: string,
  locale: string,
): Promise<boolean> => {
  return axios
    .post(
      `${baseUrl}/otp/validate_sms`,
      {
        phone,
        otp,
        source: 'angel-web',
      },
      { headers: { 'x-accept-language': locale } },
    )
    .then((res: { data: { isValid?: boolean; authorization?: string } }) => {
      if (res.data.authorization) {
        setCookie(AUTH_JWT_COOKIE, res.data?.authorization)
        return true
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export const whatsAppResendCode = (phone: E164Number | undefined, locale: string, trackingCallback: () => void) => {
  return axios
    .post(
      `${baseUrl}/otp/sms`,
      {
        phone,
        provider: 'whatsapp',
        source: 'angel-web',
      },
      { headers: { 'x-accept-language': locale } },
    )
    .then(() => {
      trackingCallback()
      return true
    })
    .catch(() => false)
}
