/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useExperiment } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'
import { useLocalStorage } from '@/hooks/useLocaleStorage'
import { TheatricalPromoType, TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { isTheatricalPromoValid } from '@/services/Theatrical/utils'
import { ReservationObject, TheatricalReleaseObject, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { useGiveawaySchedule } from '@/views/PreSales/PreSalesGiveawayView/hooks/useGiveawaySchedule'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { FreeTicketModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/FreeTicketModal'
import { useTicketsPromoContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/TicketsPromoContext'
import EarlyBirdModal from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/EarlyBirdModal'
import FlashSaleModal from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/FlashSaleModal'
import { NoFeesModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/NoFeesModal'
import { isAngelIntegratedCountry } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { BraveTheDarkGiveawayBanner } from './GiveawayModal/BraveTheDarkGiveawayModal'

interface TicketModalSectionProps {
  theatricalRelease: TheatricalReleaseObject
  isEditReservationFlow: boolean
  reservation?: ReservationObject
  promo?: TheatricalPromoType
  venue?: TheatricalShowtimeVenue
}

const getQueryParam = (param: string | string[] | undefined): string =>
  typeof param === 'string' ? param.toLowerCase() : ''

const TicketPromoModalsInner: FC<TicketModalSectionProps> = ({
  theatricalRelease,
  isEditReservationFlow,
  reservation,
  promo,
  venue,
}) => {
  const { query, asPath, isReady } = useRouter()
  const [isEarlyBirdModalOpen, setIsEarlyBirdModalOpen] = useState(false)
  const [isNoFeesModalOpen, setIsNoFeesModalOpen] = useState(false)
  const [isFlashSaleModalOpen, setIsFlashSaleModalOpen] = useState(false)
  const { venue: showtimeSelectedVenue } = useTicketsPromoContext()
  const { isFreeTicketPromo, discount, promoExperiment } = useTicketsContext()
  const theatricalSlug = theatricalRelease?.theatricalSlug
  const isAngelIntegrated = isAngelIntegratedCountry(theatricalRelease?.region?.countryCode ?? '')
  const shouldHideDiscountsForMarketing = query?.promoOverride === 'true'
  const isSceneModalPlayerPage = asPath?.toLowerCase().includes('scene') && !asPath?.toLowerCase().includes('static')
  const isGuildShareFlow = query?.flow === 'guild-share'

  // TODO: create a variations array with variations in Contentful and pass through accordingly
  const experimentDetails = useExperiment({
    key: promoExperiment?.experimentName ?? '',
    variations: ['EARLY_BIRD', 'NO_FEES'],
  })

  const experimentValue = experimentDetails?.value ?? undefined

  const getPromoBasedOnExperiment = () => {
    if (experimentValue) {
      if (promo && promo.type === experimentValue) {
        return promo
      } else {
        const experimentPromo = promo?.experimentDataCollection?.items?.find(
          (item) => item && item.type === experimentValue,
        )
        return experimentPromo ?? promo
      }
    }
    return promo
  }

  const isPromoValid = isTheatricalPromoValid(promo)
  const ticketPromo = getPromoBasedOnExperiment()

  const isPromoModalValid =
    !isEditReservationFlow &&
    reservation?.id &&
    isAngelIntegrated &&
    !shouldHideDiscountsForMarketing &&
    !isSceneModalPlayerPage &&
    !query?.highlightGroupTickets &&
    !isFreeTicketPromo &&
    isPromoValid &&
    !isGuildShareFlow &&
    getQueryParam(query?.promo) !== 'dailywire' &&
    getQueryParam(query?.promo) !== 'none' &&
    getQueryParam(query?.promo) !== 'bogo' &&
    getQueryParam(query?.promo) !== 'percent'

  const promoType = ticketPromo?.type
  const code = ticketPromo?.code

  const handleOpenModal = useCallback(
    (storageKey: string, type: string) => {
      const hasSeenModal = getBooleanFromLocalStorage(storageKey)
      if (!isPromoModalValid) return
      if (!hasSeenModal) {
        if (type === TicketPromoTypes.EarlyBird && discount) setIsEarlyBirdModalOpen(true)
        if (type === TicketPromoTypes.FlashSale && discount) setIsFlashSaleModalOpen(true)
        if (type === TicketPromoTypes.NoFees) setIsNoFeesModalOpen(true)
        writeToLocalStorage(storageKey, true)
      }
    },
    [discount, isPromoModalValid],
  )

  useEffect(() => {
    if (!isReady) return

    if (promoType && theatricalSlug && (discount || promoType === TicketPromoTypes.NoFees)) {
      return handleOpenModal(`discount-modal-opened-${promoType}-${theatricalSlug}`, promoType as string)
    }
  }, [discount, handleOpenModal, isReady, query?.promo, theatricalSlug, promoType])

  return (
    <>
      <FreeTicketModal
        maxTickets={theatricalRelease?.region?.freeTicketRequestAmount ?? 1}
        venue={showtimeSelectedVenue ?? venue}
      />

      {discount && isPromoModalValid && query.promo !== 'stop-hate' && (
        <>
          <FlashSaleModal
            theatricalName={theatricalRelease?.title as string}
            open={isFlashSaleModalOpen}
            onClose={() => setIsFlashSaleModalOpen(false)}
            discount={discount}
          />
          {promoType === TicketPromoTypes.EarlyBird && (
            <EarlyBirdModal
              theatricalSlug={theatricalSlug}
              onClose={() => setIsEarlyBirdModalOpen(false)}
              open={isEarlyBirdModalOpen}
              discount={discount}
            />
          )}
        </>
      )}
      {isPromoModalValid && promoType === TicketPromoTypes.NoFees && (
        <>
          <NoFeesModal
            code={code as string}
            isManualCode
            open={isNoFeesModalOpen}
            onClose={() => setIsNoFeesModalOpen(false)}
            theatricalSlug={theatricalSlug}
          />
        </>
      )}
    </>
  )
}

// Adding a wrapper we can use to render modals so that we we don't
// disrupt experiments in TicketPromoModalsInner
export const TicketPromoModals: FC<TicketModalSectionProps> = ({
  theatricalRelease,
  promo,
  isEditReservationFlow,
  ...rest
}) => {
  const { isFreeTicketPromo } = useTicketsContext()
  const { isActive } = useGiveawaySchedule(theatricalRelease?.theatricalSlug)
  const { query, asPath, isReady } = useRouter()
  const [showModal, setShowModal] = useState(false)

  const giveawayModalKey = 'btd-modal-giveaway'
  const isAngelIntegrated = isAngelIntegratedCountry(theatricalRelease?.region?.countryCode ?? '')
  const shouldHideDiscountsForMarketing = query?.promoOverride === 'true'
  const isSceneModalPlayerPage = asPath?.toLowerCase().includes('scene') && !asPath?.toLowerCase().includes('static')
  const btdPromos = ['brave20', 'braveteacher', 'teacherbogo']

  const getQueryParam = (param: string | string[] | undefined): string =>
    typeof param === 'string' ? param.toLowerCase() : ''
  const currentUrlPromo = getQueryParam(query?.promo) || getQueryParam(query?.discounts)
  const btdPromosActive = btdPromos.includes(currentUrlPromo)

  const {
    storedValue: hasBeenShown,
    isReady: isLocalStorageready,
    setStoredValue,
  } = useLocalStorage(giveawayModalKey, false, 'boolean')

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
    setStoredValue(true)
  }, [setStoredValue])

  const canShowModal =
    isReady &&
    isLocalStorageready &&
    !isEditReservationFlow &&
    isAngelIntegrated &&
    !shouldHideDiscountsForMarketing &&
    !isSceneModalPlayerPage &&
    !query?.highlightGroupTickets &&
    !isFreeTicketPromo

  useEffect(() => {
    if (canShowModal && !hasBeenShown && theatricalRelease?.theatricalSlug === 'brave-the-dark' && isActive) {
      setShowModal(true)
    }
  }, [theatricalRelease?.theatricalSlug, hasBeenShown, canShowModal, btdPromosActive, isActive])

  if (canShowModal) {
    return <BraveTheDarkGiveawayBanner isOpen={showModal} onClose={handleCloseModal} />
  }

  return (
    <TicketPromoModalsInner
      isEditReservationFlow={isEditReservationFlow}
      theatricalRelease={theatricalRelease}
      promo={promo}
      {...rest}
    />
  )
}

export default TicketPromoModals
