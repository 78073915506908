import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { PhoneNumber } from 'libphonenumber-js'
import { useRouter } from 'next/router'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CaptionMD } from '@/atoms/Text'
import { TheatricalShowtime, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import EmptyShowtimesState from '@/views/TicketCheckoutViews/ShowtimesView/components/EmptyShowtimesState'
import GroupTicketsButton from '@/views/TicketCheckoutViews/ShowtimesView/components/GroupTicketsButton'
import PrivateShowingButton from '@/views/TicketCheckoutViews/ShowtimesView/components/PrivateShowingButton'
import { ShowTimeLink } from '@/views/TicketCheckoutViews/ShowtimesView/components/ShowTimeLink/ShowTimeLink'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'
import { getLanguageNameUsingUserLocale } from '@/views/TicketCheckoutViews/utils'

interface ShowtimesListProps {
  showTimes: TheatricalShowtime[]
  venueId: string
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, showTime: TheatricalShowtime) => void
  shouldShowGroupTicketsButton: boolean
  shouldShowPrivateShowingButton: boolean
  openGroupTicketsModal?: (type: GroupTicketsModalTypes) => void
  openBuyoutModal?: (buyoutVenue: TheatricalShowtimeVenue, theaterChain: string) => void
  venuePhoneNumber: PhoneNumber | undefined
  isVenueIntegrated: boolean
  reservationId?: string
  className?: string
  theaterSlug: string
  countryCode: string
  venue: TheatricalShowtimeVenue
  analyticsPayload: {
    venue: TheatricalShowtimeVenue
    latitude: number | undefined
    longitude: number | undefined
    theatricalName: string
    theatricalSlug: string
    projectSlug: string
  }
}

const ENGLISH_SPEAKING_COUNTRY_CODES = new Set(['AU', 'CA', 'IE', 'NZ', 'GB', 'US', 'UK'])

const ShowtimesList = ({
  showTimes,
  venueId,
  onClick,
  venue,
  shouldShowGroupTicketsButton,
  openGroupTicketsModal,
  openBuyoutModal,
  analyticsPayload,
  isVenueIntegrated,
  theaterSlug,
  reservationId,
  countryCode,
  className,
  shouldShowPrivateShowingButton,
  venuePhoneNumber,
}: ShowtimesListProps) => {
  const { query } = useRouter()
  const { t } = useTranslate('tickets')
  const { track } = useSafeAnalytics()
  const { locale } = useLocale()

  const { venueRefinementExperiment } = useTicketsContext()

  const isPhilippinesFreeTicketFlow = !!query?.promo && countryCode === 'PH'

  const getShowtimeReservableName = useCallback(
    (seatsReservable: boolean | null | undefined) => {
      if (seatsReservable == null) return null
      if (seatsReservable) return t('reservedSeating', 'Reserved Seating')
      return t('generalAdmission', 'General Admission')
    },
    [t],
  )

  const getTheaterChain = (venueName: string) => {
    const lowerCaseVenueName = venueName.toLowerCase()

    if (lowerCaseVenueName.includes('amc')) {
      return 'amc'
    }
    if (lowerCaseVenueName.includes('cinemark') || lowerCaseVenueName.includes('century')) {
      return 'cinemark'
    }
    if (lowerCaseVenueName.includes('regal')) {
      return 'regal'
    }

    return null
  }

  const theaterChain = getTheaterChain(venue.name)

  const getSubtitleName = useCallback(
    (subtitleLanguage: string | null | undefined) => {
      if (subtitleLanguage == null) return null
      if (subtitleLanguage === locale) return t('closedCaptions', 'CC')
      return `${getLanguageNameUsingUserLocale(locale, subtitleLanguage)} ${t('ticketSubtitles', 'Subtitles')}`
    },
    [locale, t],
  )

  const getAudioLanguageName = useCallback(
    (audioLanguage: string | null | undefined) => {
      if (audioLanguage == null) return null
      if (ENGLISH_SPEAKING_COUNTRY_CODES.has(countryCode) && audioLanguage === 'en') return null
      return `${getLanguageNameUsingUserLocale(locale, audioLanguage)} ${t('ticketSpoken', 'Spoken')}`
    },
    [locale, countryCode, t],
  )

  const handleGroupTicketClick = useCallback(() => {
    track('Clicked Group Tickets Available')
    openGroupTicketsModal && openGroupTicketsModal('tickets')
  }, [openGroupTicketsModal, track])

  const handleBuyoutClick = useCallback(
    (buyoutVenue: TheatricalShowtimeVenue, theaterChain: string) => {
      openBuyoutModal && openBuyoutModal(buyoutVenue, theaterChain)
    },
    [openBuyoutModal],
  )

  const organizedShowtimes = useMemo(() => {
    return organizeShowtimes(showTimes)
  }, [showTimes])

  if (showTimes?.length === 0 && !isPhilippinesFreeTicketFlow) {
    return (
      <EmptyShowtimesState
        venuePhoneNumber={venuePhoneNumber}
        theatricalSlug={theaterSlug}
        venue={venue}
        analyticsPayload={analyticsPayload}
      />
    )
  }

  return (
    <FlexColumn className={className}>
      {organizedShowtimes?.map((organizedList, index) => {
        const reservableName = getShowtimeReservableName(organizedList[0].reservable)
        const subtitleName = getSubtitleName(organizedList[0].subtitleLanguage)
        const audioName = getAudioLanguageName(organizedList[0].language)

        return (
          <FlexColumn className={classNames('gap-3')} key={`${venueId}-${index}`}>
            <FlexRow className="gap-2">
              {!!subtitleName && (
                <CaptionMD className="capitalize" color="core-gray-600">
                  {subtitleName}
                </CaptionMD>
              )}
              {!!audioName && (
                <FlexRow className="gap-2">
                  {!!subtitleName && <div className="h-1 w-1 rounded-full bg-core-gray-600" />}
                  <CaptionMD className="capitalize" color="core-gray-600">
                    {audioName}
                  </CaptionMD>
                </FlexRow>
              )}
              {reservableName && (
                <FlexRow className="gap-2">
                  {(!!subtitleName || !!audioName) && <div className="h-1 w-1 rounded-full bg-core-gray-600" />}
                  <CaptionMD color="core-gray-600">{reservableName}</CaptionMD>
                </FlexRow>
              )}
            </FlexRow>
            <div
              className={classNames(
                'auto flex flex-wrap justify-items-start',
                venueRefinementExperiment ? 'gap-2' : 'gap-4',
              )}
            >
              {organizedList.map((showTime, index) => {
                if (!showTime.utcStartTime) return null
                return (
                  <ShowTimeLink
                    countryCode={countryCode}
                    reservationId={reservationId}
                    theaterSlug={theaterSlug}
                    isVenueIntegrated={isVenueIntegrated}
                    key={`${venueId}-${showTime.utcStartTime}-${index}`}
                    showTime={showTime}
                    query={query}
                    onClick={(event) => onClick(event, showTime)}
                  />
                )
              })}
              {shouldShowGroupTicketsButton && <GroupTicketsButton onClick={handleGroupTicketClick} />}
              {shouldShowPrivateShowingButton && (theaterChain || venue.phone) && (
                <PrivateShowingButton
                  venue={venue}
                  theaterChain={theaterChain || ''}
                  onClick={() => handleBuyoutClick(venue, theaterChain || '')}
                />
              )}
            </div>
          </FlexColumn>
        )
      })}
    </FlexColumn>
  )
}

export default ShowtimesList

const getShowtimeReservability = (showtime: TheatricalShowtime) => {
  if (showtime?.reservable == null) return 'unknown'
  if (showtime.reservable) return 'reservable'
  return 'nonReservable'
}

const organizeShowtimes = (showtimes: TheatricalShowtime[]): TheatricalShowtime[][] => {
  const organizedByLanguage = showtimes.reduce((acc, showtime) => {
    const seatsReservable = getShowtimeReservability(showtime)
    const subtitleLanguage = showtime?.subtitleLanguage || 'noSubtitle'
    const language = showtime?.language || 'noLanguage'
    const tempKey = `${seatsReservable}${subtitleLanguage}${language}`

    return { ...acc, [tempKey]: [...(acc[tempKey] ?? []), showtime] }
  }, {} as Record<string, TheatricalShowtime[]>)

  return sortShowtimesByLanguage(Object.values(organizedByLanguage))
}

const sortShowtimesByLanguage = (showtimes: TheatricalShowtime[][]) => {
  const sortedShowtimesBySubtitle = sortBySubtitleLanguage(showtimes)
  return sortByAudioLanguage(sortedShowtimesBySubtitle)
}

const sortBySubtitleLanguage = (showtimes: TheatricalShowtime[][]) => {
  return showtimes.sort((a, b) => {
    if (a.some((showtime) => showtime.subtitleLanguage == null)) return -1
    if (b.some((showtime) => showtime.subtitleLanguage == null)) return 1
    if (a.some((showtime) => showtime.subtitleLanguage == 'en')) return -1
    if (b.some((showtime) => showtime.subtitleLanguage == 'en')) return 1
    return 0
  })
}

const sortByAudioLanguage = (showtimes: TheatricalShowtime[][]) => {
  return showtimes.sort((a, b) => {
    if (a.some((showtime) => showtime.language == 'en' && showtime.subtitleLanguage == null)) return -1
    if (b.some((showtime) => showtime.language == 'en' && showtime.subtitleLanguage == null)) return 1
    return 0
  })
}
