import React, { FC } from 'react'
import classNames from 'classnames'
import { AsSpan, Text } from '@/atoms/Text'

interface Props {
  title: string
  subTitle: string
  selected: boolean
  onClick: () => void
  ticketQuantity: number
  ticketPrice: number
  paidQuantity: number
}

export const TicketOption: FC<Props> = ({
  title,
  subTitle,
  selected,
  onClick,
  ticketPrice,
  ticketQuantity,
  paidQuantity,
}) => {
  return (
    <div key={title} className="radio grow-1 select-none" onClick={onClick}>
      <label className="flex w-full cursor-pointer items-center justify-center gap-2">
        <span
          className={classNames(
            'flex gap-2 items-center py-3 pl-4 pr-6 border rounded-full flex-grow-1 max-w-[380px] w-full',
            selected ? 'border-core-gray-950 bg-core-gray-950' : 'border-core-gray-400 hover:bg-core-gray-100',
          )}
        >
          <span
            className={classNames(
              'relative h-6 w-6 rounded-full border',
              selected ? 'bg-oxide-bright border-oxide-bright' : 'border-core-gray-400',
            )}
          >
            {selected && (
              <span className="absolute inset-1/2 h-2.5 w-2.5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black" />
            )}
          </span>
          <span className="flex flex-col gap-1 text-left">
            <Text
              as={AsSpan}
              weight="semibold"
              className={classNames('block text-base leading-4', selected ? 'text-white' : 'text-core-gray-950')}
            >
              {title}
            </Text>
            <Text
              as={AsSpan}
              weight="semibold"
              className={classNames(
                'block text-sm leading-[0.875rem]',
                selected ? '!text-core-gray-500' : '!text-core-gray-600',
              )}
            >
              {subTitle}
            </Text>
          </span>
          <span className="ml-auto flex flex-col gap-1 text-right">
            <Text
              as={AsSpan}
              weight="semibold"
              color="core-gray-600"
              className={classNames(
                'block line-through leading-4 text-base',
                selected ? '!text-core-gray-500' : '!text-core-gray-600',
              )}
            >
              ${ticketQuantity && ticketQuantity * ticketPrice}
            </Text>
            <Text
              as={AsSpan}
              weight="semibold"
              className={classNames('text-sm leading-[0.875rem]', selected ? '!text-white' : '!text-core-gray-950')}
            >
              ${ticketQuantity && paidQuantity * ticketPrice}
            </Text>
          </span>
        </span>
      </label>
    </div>
  )
}
