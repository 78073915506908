import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { slugs } from '@/constants'
import { TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { ReservationObject, TheatricalReleaseObject } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { DiscountType, useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import ChangeReservationBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/ChangeReservationBanner'
import { TicketPromoBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner'
import { BraveTheDarkBOGO } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkBOGO'
import { BraveTheDarkBraveTeacher } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkBraveTeacher'
import { BraveTheDarkDiscountBanner } from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/DiscountBanner/BraveTheDarkDiscountBanner'
import NoFeesBanner from '@/views/TicketCheckoutViews/ShowtimesView/components/banners/NoFeesBanner'
import { isAngelIntegratedCountry } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'

interface BannerSectionProps {
  reservation?: ReservationObject
  isEditReservationFlow: boolean
  theatricalRelease: TheatricalReleaseObject
  promo?: TheatricalPromoType
  calendarRef?: React.RefObject<HTMLDivElement>
}

const TicketBannerSection: FC<BannerSectionProps> = ({
  promo,
  reservation,
  isEditReservationFlow,
  theatricalRelease,
  calendarRef,
}) => {
  const PROMO_BANNERS = {
    brave20: <BraveTheDarkDiscountBanner mobileclassName="!hidden" />,
    teacherbogo: <BraveTheDarkBOGO mobileclassName="!hidden" />,
    braveteacher: <BraveTheDarkBraveTeacher mobileclassName="!hidden" />,
  }
  const { t } = useTranslate('tickets')
  const { discount } = useTicketsContext()
  const { query } = useRouter()
  const countryCode = theatricalRelease?.region?.countryCode
  const validPromos = ['brave20', 'braveteacher', 'teacherbogo']
  const getQueryParam = (param: string | string[] | undefined): string =>
    typeof param === 'string' ? param.toLowerCase() : ''

  const currentUrlPromo = getQueryParam(query?.promo) || getQueryParam(query?.discounts)
  const isBravePromo = validPromos.includes(currentUrlPromo)

  return (
    <>
      {reservation?.id && !isEditReservationFlow && isAngelIntegratedCountry(countryCode) && (
        <TicketPromoBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className={'mb-4 rounded-xl !pr-0'}
          promo={promo}
          discount={discount as DiscountType}
          calendarRef={calendarRef}
        />
      )}
      {reservation?.priorReservation?.id &&
        reservation?.isChangeReservation &&
        isAngelIntegratedCountry(countryCode) && (
          <ChangeReservationBanner
            description={t('pleaseSelectANewShowtimeBelow', 'Please select a new showtime below.')}
            priorReservation={reservation?.priorReservation}
            step={1}
          />
        )}
      {theatricalRelease?.theatricalSlug === slugs.braveTheDark && (
        <NoFeesBanner
          theatricalSlug={theatricalRelease?.theatricalSlug}
          className={'mb-4  rounded-xl !pr-0'}
          code="NOFEES"
        />
      )}
      {isBravePromo && currentUrlPromo && PROMO_BANNERS[currentUrlPromo as keyof typeof PROMO_BANNERS] && (
        <>{PROMO_BANNERS[currentUrlPromo as keyof typeof PROMO_BANNERS]}</>
      )}
    </>
  )
}

export default TicketBannerSection
