import React, { FC } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { InView } from 'react-intersection-observer'
import { FlexColumn } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { AsH2, Text } from '@/atoms/Text'
import { useUser } from '@/services/UserService'
import { TheatricalReleaseObject, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { useTranslate } from '@/utils/translate/translate-client'
import { useGiveawaySchedule } from '@/views/PreSales/PreSalesGiveawayView/hooks/useGiveawaySchedule'
import { EmptyStateGiveaway } from '@/views/TicketCheckoutViews/ShowtimesView/components/EmptyStateGiveaway'
import { useTicketsContext } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'
import { VenueItem } from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueItem'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'
import { GuildTicketsBanner } from './banners/GuildTicketsBanner'

export interface LocaleWarningModalState {
  isOpen: boolean
  warning: string
  callback: () => void
}

interface VenueListProps {
  venues: TheatricalShowtimeVenue[]
  theatricalRelease: TheatricalReleaseObject
  theaterSlug: string
  reservationId?: string
  className?: string
  isGroupTicketsModalOpen: boolean
  isBuyoutModalOpen: boolean
  openGroupTicketsModal: (type: GroupTicketsModalTypes) => void
  openBuyoutModal: (buyoutVenue: TheatricalShowtimeVenue, theaterChain: string) => void
  setLocaleWarningModalState: React.Dispatch<React.SetStateAction<LocaleWarningModalState>>
  theatricalSlug: string
  showGuildTicketBanner: boolean
}

const VenueList: FC<VenueListProps> = ({
  venues,
  theatricalRelease,
  theaterSlug,
  reservationId,
  isGroupTicketsModalOpen,
  openBuyoutModal,
  openGroupTicketsModal,
  setLocaleWarningModalState,
  isBuyoutModalOpen,
  className,
  theatricalSlug,
  showGuildTicketBanner,
}) => {
  const { query } = useRouter()
  const isEmpty = venues?.length === 0
  const { trackProductViewed } = useEcommerceEvents()
  const { isLoggedIn } = useUser()
  const { areGuildTicketsAvailable, discountCodes } = useGuildTickets(theatricalSlug)
  const { venueRefinementExperiment } = useTicketsContext()

  if (isEmpty) return <VenueListEmptyState theatricalSlug={theatricalSlug} />

  return (
    <>
      <InView
        as="ul"
        className={classNames('flex flex-col', className, venueRefinementExperiment ? 'gap-2' : 'gap-4')}
        triggerOnce
        onChange={(inView) => {
          if (!inView) return

          trackProductViewed({
            category: 'theatrical ticket',
            funnel: 'theatrical',
            sku: `ticket-${theaterSlug}`,
            product_id: `ticket-${theaterSlug}`,
            name: `${theaterSlug} theatrical ticket`,
            price: 15,
            currency: 'USD',
            quantity: 1,
            projectSlug: theaterSlug,
          })
        }}
      >
        {venues.map((venue, idx) => {
          const showGuildBanner = idx === 0 && venues.length > 1 && areGuildTicketsAvailable && isLoggedIn

          return (
            <React.Fragment key={venue.id}>
              <VenueItem
                key={venue.id}
                theaterSlug={theaterSlug}
                reservationId={reservationId}
                query={query}
                theatricalRelease={theatricalRelease}
                venue={venue}
                setLocaleWarningModalState={setLocaleWarningModalState}
                isGroupTicketsModalOpen={isGroupTicketsModalOpen}
                openGroupTicketsModal={openGroupTicketsModal}
                isBuyoutModalOpen={isBuyoutModalOpen}
                openBuyoutModal={openBuyoutModal}
                zIndex={venues.length - idx} // this is a hack so that popovers don't get cut off
              />
              {showGuildBanner && showGuildTicketBanner && (
                <FlexColumn className="w-full items-center gap-6 text-center md:hidden">
                  <GuildTicketsBanner
                    shouldUseGradient
                    theatricalSlug={theatricalSlug}
                    projectName={theatricalRelease?.title ?? ''}
                    discountCodes={discountCodes}
                    className="relative z-10 w-full overflow-hidden rounded-2xl md:hidden"
                    mobileClassName="text-left z-[11]"
                  />
                </FlexColumn>
              )}
            </React.Fragment>
          )
        })}
      </InView>
    </>
  )
}
export default VenueList

interface VenueListEmptyStateProps {
  theatricalSlug: string
}

const VenueListEmptyState: FC<VenueListEmptyStateProps> = ({ theatricalSlug }) => {
  const { t } = useTranslate('tickets')
  const { isActive, isReady } = useGiveawaySchedule(theatricalSlug)
  if (!isReady) return null

  if (isActive) {
    return <EmptyStateGiveaway theatricalSlug={theatricalSlug} />
  }
  return (
    <FlexColumn className="my-6 items-center gap-6 text-center">
      <Image
        priority
        className="md:!h-[136px] md:!w-[180px]"
        alt=""
        src="/v1712858189/share-seats-illustration-colored-02_2x.webp"
        width={120}
        height={91}
      />
      <FlexColumn className="gap-2">
        <Text as={AsH2} className="photon-title-sm md:photon-title-md font-semibold" weight="bold">
          {t('noShowtimesAvailable', 'No Showtimes Available')}
        </Text>
        <Text className="photon-paragraph-sm md:photon-paragraph-md max-w-lg" color="core-gray-700">
          {t(
            'atTheMomentTheresNoShowtimesAvailable',
            "At the moment, there aren't any showtimes in your area. Feel free to explore other locations or try another date!",
          )}
        </Text>
      </FlexColumn>
    </FlexColumn>
  )
}
