import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Button, LinkButton } from '@/atoms/Button'
import { FlexColumn } from '@/atoms/FlexContainers'
import { Text } from '@/atoms/Text'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { useSafeTrack } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { isStagingEnvironment } from '@/utils/environment-utils'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { GroupTicketsOptions } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTickets/GroupTicketsOptions'
import { GroupTicketsModalTypes } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/GroupTicketsModal/GroupTicketsModal'

const GROUP_DISCOUNT_RATE = 0.5

interface GroupTicketsV1Props {
  setModalType: React.Dispatch<React.SetStateAction<GroupTicketsModalTypes>>
  groupTicketOptions: GroupTicketOption[]
  ticketPrice: number
  theatricalSlug: string
}

export const GroupTickets: React.FC<GroupTicketsV1Props> = ({
  setModalType,
  groupTicketOptions,
  ticketPrice,
  theatricalSlug,
}) => {
  const { t } = useTranslate('tickets')
  const track = useSafeTrack()
  const { trackProductAdded, trackProductViewed } = useEcommerceEvents()
  const [groupTicketOption, setGroupTicketOption] = useState(
    groupTicketOptions?.[3] ?? groupTicketOptions?.[2] ?? groupTicketOptions?.[1] ?? groupTicketOptions?.[0],
  )
  const stripeUrl = isStagingEnvironment()
    ? groupTicketOption?.stripeStagingUrl ?? ''
    : groupTicketOption?.stripeUrl ?? ''

  useEffect(() => {
    track('Group Tickets Options Viewed')
  }, [track])

  const handleCtaClick = () => {
    // TODO: deprecated - remove after release of trackProductAdded and marketing's (Kylo's) blessing
    track('Group Tickets Checkout Started')

    trackProductAdded({
      category: 'group tickets',
      funnel: 'theatrical',
      name: `${groupTicketOption.title} ${theatricalSlug}`,
      price: Number((ticketPrice * GROUP_DISCOUNT_RATE).toFixed(2)),
      currency: 'USD',
      quantity: groupTicketOption?.totalTicketCount ?? 0,
      product_id: `group-tickets-${theatricalSlug}`,
      sku: `group-tickets-${theatricalSlug}`,
      url: groupTicketOption?.stripeUrl,
      projectSlug: theatricalSlug,
    })
  }

  return (
    <InView
      triggerOnce
      onChange={(inView) => {
        if (!inView) return

        trackProductViewed({
          category: 'group tickets',
          funnel: 'theatrical',
          name: `${groupTicketOption.title} ${theatricalSlug}`,
          price: Number((ticketPrice * GROUP_DISCOUNT_RATE).toFixed(2)),
          currency: 'USD',
          quantity: groupTicketOption?.totalTicketCount ?? 0,
          product_id: `group-tickets-${theatricalSlug}`,
          sku: `group-tickets-${theatricalSlug}`,
          url: groupTicketOption?.stripeUrl,
          projectSlug: theatricalSlug,
          value: groupTicketOption.totalTicketCount
            ? groupTicketOption.totalTicketCount * ticketPrice * GROUP_DISCOUNT_RATE
            : 0,
        })
      }}
    >
      <Text className="photon-heading-xs md:photon-heading-sm block text-center" color="core-gray-950" weight="bold">
        {t('flexGroupTickets', 'Flex Group Tickets')}
      </Text>
      <Text
        color="core-gray-700"
        weight="medium"
        className="photon-paragraph-sm md:photon-paragraph-md mt-1 block pb-6 text-center"
      >
        <Translate t={t} i18nKey="youCanUseTheseTicketsOnAnySoundOfFreedomV6">
          Tickets only available at Angel Partner Theaters. Ticket prices estimated at $15. Additional charges may
          apply.{' '}
          <Button
            onClick={() => setModalType('faq')}
            className="inline font-semibold text-core-gray-950 underline"
            variant="plain"
          >
            Learn More
          </Button>
        </Translate>
      </Text>
      <GroupTicketsOptions
        setGroupTicketOption={setGroupTicketOption}
        groupTicketOption={groupTicketOption}
        groupTicketOptions={groupTicketOptions}
        ticketPrice={ticketPrice}
      />
      <FlexColumn className="items-center gap-6">
        <LinkButton
          onClick={handleCtaClick}
          className="mx-auto flex w-full max-w-[380px] items-center justify-center rounded-[14px] px-7 py-[17px] md:w-fit"
          href={stripeUrl}
          variant="black"
        >
          {t('checkoutGroupTickets', 'Checkout')}
        </LinkButton>
      </FlexColumn>
    </InView>
  )
}
