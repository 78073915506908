import { getItemOrFirstEntry } from '@/utils/array-utils'
import { logger } from '@/utils/logging'
import { QueryParams } from '@/utils/types'

export type TicketPromo = 'sof-claim-free' | 'claim-free-ticket'

export function getPromoFromQuery(query: QueryParams): TicketPromo | undefined {
  const maybePromo = query.promo
  if (!maybePromo) return

  const promo = getItemOrFirstEntry(maybePromo)
  if (promo === 'sof-claim-free') return 'sof-claim-free'
  if (promo === 'claim-free-ticket') return 'claim-free-ticket'
  return
}

export function convertUTCToLocalTime(utcString: string, offsetHours: string, locale: string) {
  try {
    const utcDate = new Date(utcString)
    const tzDifference = parseFloat(offsetHours) * 60 + utcDate.getTimezoneOffset()
    const offsetTime = new Date(utcDate.getTime() + tzDifference * 60 * 1000)

    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(offsetTime)
  } catch (error) {
    logger().warn('Error converting UTC to local time', { utcString, offsetHours, locale, error })
  }
}
