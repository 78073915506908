import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import classNames from 'classnames'
import { getCookie } from 'cookies-next'
import { CountryCode } from 'libphonenumber-js'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { CheckBox } from '@/atoms/CheckBox'
import { CircleBusyIndicator } from '@/atoms/CircleBusyIndicator'
import { ExternalLink } from '@/atoms/ExternalLink'
import { ArrowLeftIcon } from '@/atoms/Icons/ArrowLeftIcon'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { AngelVerificationInput } from '@/atoms/Input/VerificationInput/AngelVerificationInput'
import { HeadingSM, HeadingXS, ParagraphLG, ParagraphMD } from '@/atoms/Text'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { latamCountries } from '@/constants/latamCountries'
import { useTheatricalRegionContext } from '@/contexts/TheatricalRegionContext'
import { Avatar } from '@/molecules/Avatar'
import { Modal } from '@/molecules/Modal'
import { WhatsAppSignUp } from '@/organisms/WhatsAppSignUp/WhatsAppSignUp'
import { useBotDetection } from '@/services/BotDetection'
import { generateRecaptchaToken } from '@/services/Captcha/Captcha'
import { TheatricalMovie } from '@/services/CmsService/TheatricReleases'
import { useClaimTicketMutation } from '@/services/TicketsService/TicketsService'
import { useUser } from '@/services/UserService'
import type { ClaimReasonKey, TheatricalReleaseObject, TheatricalShowtimeVenue } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { AnalyticsEvent, useSafeTrack } from '@/utils/analytics'
import { useLoginUrl } from '@/utils/auth-utils'
import { isProductionEnvironment } from '@/utils/environment-utils'
import { getStringFromLocalStorage, removeFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { logger } from '@/utils/logging'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { getUserDisplayName } from '@/utils/users'
import { MultiFreeTickets } from '@/views/TicketCheckoutViews/ShowtimesView/components/MultiFreeTickets'
import { HideEmailSignInExperiment } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/HideEmailSignInEpxeriment'
import { InstantPromoCodeV2 } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/InstantPromoCodeV2'
import PromoSuccess from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/PromoSuccess'
import { TicketPromoModalIntro } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsPromo/TicketPromoModalIntro'
import { useTicketsPromoContext } from './TicketsPromoContext'

export type ModalState =
  | 'intro'
  | 'login_required'
  | 'select_reasons'
  | 'email_sent'
  | 'error'
  | 'loading'
  | 'duplicate_error'
  | 'instant_promo_code'
  | 'whatsapp_signup'
  | 'otp_check'

interface BuyTicketsLinkProps {
  isPrimaryButton?: boolean
  className?: string
  ticketUrl: string | undefined
  trackClick: (eventName: AnalyticsEvent, customAttributes?: unknown) => void
  text: string
  theatricalReleaseRegion?: TheatricalReleaseObject['region']
}

interface TicketsPromoModalProps {
  foo?: string
  isImmediateVoucherIssueEnabled?: boolean
  movies?: TheatricalMovie[] | []
  venue: TheatricalShowtimeVenue | undefined
  maxTickets: number
}

const otpBaseUrl = isProductionEnvironment() ? 'https://auth.angel.com' : 'https://auth.stg.angel.com'

export const BuyTicketsLink: React.FC<BuyTicketsLinkProps> = ({
  isPrimaryButton = false,
  ticketUrl,
  trackClick,
  text,
  className,
  theatricalReleaseRegion,
}) => {
  if (!ticketUrl) return null

  return (
    <div className={classNames('w-full', { 'mt-2': !isPrimaryButton, 'mt-5': isPrimaryButton })}>
      {theatricalReleaseRegion?.useSimpleWaitlistOverlay && typeof theatricalReleaseRegion?.partnerUrl === 'string' ? (
        <LinkButton
          className={className}
          variant="plain"
          href={theatricalReleaseRegion.partnerUrl}
          outline={true}
          external
        >
          <ParagraphMD weight="bold">{text}</ParagraphMD>
        </LinkButton>
      ) : (
        <LinkButton
          className={className}
          variant="plain"
          href={ticketUrl}
          outline={true}
          external
          onClick={() => trackClick('Buy Tickets Instead Clicked')}
        >
          <ParagraphMD weight="bold">{text}</ParagraphMD>
        </LinkButton>
      )}
    </div>
  )
}

export const FreeTicketModal: React.FC<TicketsPromoModalProps> = ({
  isImmediateVoucherIssueEnabled = false,
  movies,
  venue,
  maxTickets,
}) => {
  const { user, loading: userIsLoading, refetch: refetchUser } = useUser()
  const { query, asPath } = useRouter()
  const { locale } = useLocale()
  const track = useSafeTrack()
  const { t } = useTranslate('tickets')
  const { claimTicket, claimTicketResponse } = useClaimTicketMutation()
  const [promiseChecked, setPromiseChecked] = useState(false)
  const [ticketCount, setTicketCount] = useState(1)
  const { hasShownIntro, closePromoModal, isPromoModalOpen, ticketUrl, theatricalRelease, theatricalSlug } =
    useTicketsPromoContext()
  const theatricalRegionContext = useTheatricalRegionContext()
  const isLATAM =
    latamCountries.has(theatricalRegionContext?.userCountry?.toUpperCase()) ||
    theatricalRegionContext?.regionCode?.toLowerCase() === 'latam'

  const redirectBackPath = useMemo(() => {
    return asPath
  }, [asPath])

  const [didError, setDidError] = useState(false)
  const { loginUrl, trackLoginStarted } = useLoginUrl(redirectBackPath)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [modalState, setModalState] = useState<ModalState>('intro')
  const [whatsAppVerified, setWhatsAppVerified] = useState<boolean | null>(null)
  const [employeeNumber, setEmployeeNumber] = useState<string>('')
  const [selectedMovie, setSelectedMovie] = useState<string | null>(null)
  const [otp, setOtp] = useState<string>('')
  const userEmail = user?.email
  const waitingForRequiredOPT = modalState === 'otp_check' && (!otp || otp.length < 6)

  const shouldShowMovieSelect = useMemo(() => {
    return !!(query?.movieSelect && movies && movies?.length > 1 && !selectedMovie)
  }, [query?.movieSelect, movies, selectedMovie])

  const ticketButtonText = t('buyTicketsInsteadTitleCase', 'Buy Tickets Instead')

  const { isBot } = useBotDetection()
  const [isProcessing, setIsProcessing] = useState(false)

  const emailAddress = user?.email
  const shouldShowInstantRedemption = useMemo(() => {
    return (
      !!claimTicketResponse?.addUserToWaitlist?.voucherCodes?.length &&
      claimTicketResponse?.addUserToWaitlist?.showtimeLink
    )
  }, [
    claimTicketResponse?.addUserToWaitlist?.showtimeLink,
    claimTicketResponse?.addUserToWaitlist?.voucherCodes?.length,
  ])

  const companyName = useMemo(() => {
    if (!query?.company) return null
    const companyNameParam = query?.company as string
    const splitCompanyName = companyNameParam.split('-')
    if (splitCompanyName.length === 1) return companyNameParam
    return splitCompanyName.join(' ')
  }, [query?.company])

  const isCompanyFreeTicketFlow = useMemo(() => {
    return !!companyName
  }, [companyName])

  const sendOTP = useCallback((): void => {
    setModalState('otp_check')
    setIsProcessing(true)

    const headers = {
      'x-accept-language': locale,
      authorization: getCookie(AUTH_JWT_COOKIE),
    }

    axios
      .post(`${otpBaseUrl}/authenticated_otp/email`, {}, { headers })
      .then(({ status }: AxiosResponse) => {
        if (status !== 200) {
          setModalState('error')
          logger().warn('Otp error', { status, userEmail })
        }
        setIsProcessing(false)
      })
      .catch((err) => {
        setModalState('error')
        setIsProcessing(false)
        logger().error('OTP email send failed', { userEmail }, err)
        track('OTP email send failed', { userEmail })
      })
  }, [locale, track, setModalState, setIsProcessing, userEmail])

  const setEmployeeFreeTicketNumber = useCallback((employeeFreeTicketNumber: string) => {
    setEmployeeNumber(employeeFreeTicketNumber)
    writeToLocalStorage('employeeNumber', employeeFreeTicketNumber)
  }, [])

  const setEmployeeFreeTicketNumberFromLocalStorage = useCallback(() => {
    const employeeNumberFromLocal = getStringFromLocalStorage('employeeNumber')
    if (employeeNumberFromLocal) return setEmployeeNumber(employeeNumberFromLocal)
  }, [])

  const isOnlineRedeemable = useMemo(() => {
    if (claimTicketResponse?.addUserToWaitlist?.isOnlineRedeemable == null) return true
    return claimTicketResponse?.addUserToWaitlist?.isOnlineRedeemable === true
  }, [claimTicketResponse?.addUserToWaitlist?.isOnlineRedeemable])

  const trackClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (eventName: AnalyticsEvent, customAttributes?: any) => {
      const payload = {
        venue,
        theatricalName: theatricalRelease.title,
        theatricalSlug: theatricalRelease.theatricalSlug,
        eventSlug: theatricalRelease.theatricalSlug,
        projectSlug: theatricalRelease.projectSlug,
        showtimeLink: ticketUrl,
        // TODO selectedShowtime, need to rework the context and load this from useTicketsPromoContext
        ...customAttributes,
      }
      track(eventName, payload)
    },
    [track, venue, theatricalRelease, ticketUrl],
  )

  useEffect(() => {
    if (modalState === 'select_reasons') {
      setIsProcessing(false)
    }
  }, [modalState])

  const theatricalReleaseRegion = theatricalRelease.region

  const isPartnerPage = theatricalReleaseRegion?.useSimpleWaitlistOverlay && query?.promo === 'claim-free-ticket'

  const buildPayload = useCallback(
    (recaptchaToken: string | undefined) => {
      let payload
      const reasons: ClaimReasonKey[] = ['PURCHASE_COMMITMENT']

      const selectedShowtimeJsonString = localStorage.getItem('selectedShowtime')
      if (isPartnerPage) {
        payload = {
          eventSlug: theatricalSlug,
          reasons,
          utcStartTime: new Date(),
          venue: {
            name: theatricalReleaseRegion?.defaultTicketingProviderSlug,
            isFeatured: false,
          },
          showtimeLink: theatricalReleaseRegion?.defaultTicketingProviderSlug,
          immediateVoucherIssue: isImmediateVoucherIssueEnabled,
          quantity: ticketCount,
          ticketingProviderSlug: theatricalReleaseRegion?.defaultTicketingProviderSlug,
          recaptchaToken,
          otp: otp || undefined,
        }
      } else if (selectedShowtimeJsonString) {
        const selectedShowtime = JSON.parse(selectedShowtimeJsonString)

        payload = {
          eventSlug: theatricalSlug,
          reasons,
          utcStartTime: selectedShowtime.utcStartTime,
          venue: {
            name: selectedShowtime.venue.name,
            isFeatured: selectedShowtime.venue.ticketingProviderSlug === 'atom' ? true : false,
          },
          showtimeLink: selectedShowtime.ticketUrl,
          immediateVoucherIssue: isImmediateVoucherIssueEnabled,
          quantity: ticketCount,
          ticketingProviderSlug: selectedShowtime?.venue?.ticketingProviderSlug,
          recaptchaToken,
          otp: otp || undefined,
        }
      } else {
        payload = {
          eventSlug: theatricalSlug,
          reasons,
          utcStartTime: venue?.showtimes?.find((x) => x.ticketUrl === ticketUrl)?.utcStartTime,
          venue: {
            name: venue?.name,
            isFeatured: venue?.ticketingProviderSlug === 'atom' ? true : false,
          },
          showtimeLink: ticketUrl,
          immediateVoucherIssue: isImmediateVoucherIssueEnabled,
          quantity: ticketCount,
          ticketingProviderSlug: venue?.ticketingProviderSlug,
          recaptchaToken,
          otp: otp || undefined,
        }
      }

      if (isCompanyFreeTicketFlow && companyName && employeeNumber) {
        payload = { ...payload, employerName: companyName, employeeId: employeeNumber }
      }

      if (theatricalRegionContext?.regionCode === 'DE') {
        payload.ticketingProviderSlug = 'germany-acardo'
        payload.utcStartTime = '2023-10-01T00:00:00.000Z'
        payload.immediateVoucherIssue = false
        payload.venue.isFeatured = true
        payload.venue.name = 'Acardo'
      }

      return payload
    },
    [
      isPartnerPage,
      isCompanyFreeTicketFlow,
      companyName,
      employeeNumber,
      theatricalRegionContext?.regionCode,
      theatricalSlug,
      theatricalReleaseRegion?.defaultTicketingProviderSlug,
      isImmediateVoucherIssueEnabled,
      ticketCount,
      otp,
      venue?.showtimes,
      venue?.name,
      venue?.ticketingProviderSlug,
      ticketUrl,
    ],
  )

  const handleClaimTickets = useCallback(async () => {
    if (modalState !== 'otp_check' && !whatsAppVerified) return sendOTP()

    try {
      setIsProcessing(true)
      if (isBot) return setModalState('error')
      const recaptchaToken = await generateRecaptchaToken('free_ticket')
      const payload = buildPayload(recaptchaToken)

      trackClick('Claim Free Ticket', {
        reasons: payload.reasons,
        utcStartTime: payload.utcStartTime, // TODO remove once `selectedShowtime` is available from the context on the base analytics payload
        showtimeLink: payload.showtimeLink,
        quantity: ticketCount,
      })

      await claimTicket(payload)
      removeFromLocalStorage('employeeNumber')
    } catch (err: unknown) {
      setIsProcessing(false)
      logger().warn('Error happened during claim free tickets', { modalState, whatsAppVerified }, err)
      if (err instanceof Error) {
        const msg = err?.message
        if (
          msg === 'User is already in wait list.' ||
          msg === 'User has exceeded the maximum number of ticket requests.'
        ) {
          return setModalState('duplicate_error')
        }
        if (msg === 'Failed to add user to wait list.') return setModalState('error')
        setDidError(true)
      }
      setDidError(true)
    }
  }, [isBot, modalState, whatsAppVerified, sendOTP, trackClick, ticketCount, claimTicket, buildPayload])

  const handleTicketCountChange = useCallback(
    (action: string, maxTicketsCount: number) => {
      if (action === 'increment' && ticketCount < maxTicketsCount) {
        setTicketCount(ticketCount + 1)
      } else if (action === 'decrement' && ticketCount > 1) {
        setTicketCount(ticketCount - 1)
      }
    },
    [setTicketCount, ticketCount],
  )

  useEffect(() => {
    if (!hasShownIntro || !ticketUrl) return setModalState('intro')
    if (didError) return setModalState('error')
    if (userIsLoading) return setModalState('loading')
    // Tweak logic to support whatsapp without a user
    if (!user && !whatsAppVerified) return setModalState('login_required')
    if (!isOnlineRedeemable && claimTicketResponse?.addUserToWaitlist?.userId) return setModalState('email_sent')
    if (shouldShowInstantRedemption) return setModalState('instant_promo_code')
    if (claimTicketResponse?.addUserToWaitlist?.userId) return setModalState('email_sent')
    if (isCompanyFreeTicketFlow && !employeeNumber) {
      setEmployeeFreeTicketNumberFromLocalStorage()
    }
    return setModalState('select_reasons')
  }, [
    claimTicketResponse?.addUserToWaitlist?.userId,
    didError,
    hasShownIntro,
    isOnlineRedeemable,
    shouldShowInstantRedemption,
    ticketUrl,
    user,
    userIsLoading,
    isCompanyFreeTicketFlow,
    employeeNumber,
    setEmployeeFreeTicketNumberFromLocalStorage,
    whatsAppVerified,
  ])

  return (
    <Modal
      className="z-[99999]"
      panelClassesOverride={`inline-block overflow-hidden transition-all transform 
      w-full sm:w-fit py-8 px-4 align-middle text-core-gray-950`}
      isOpen={isPromoModalOpen}
      onClose={noop}
      transition="appear"
      backgroundOverlayClassName={shouldShowMovieSelect && isPromoModalOpen ? '!opacity-[0.98] !bg-black' : ''}
    >
      <div className="relative flex min-h-[440px] w-full flex-col items-center justify-center overflow-hidden rounded-2xl bg-white px-1 py-10 sm:w-[512px] md:p-10">
        <When condition={modalState === 'whatsapp_signup'}>
          <div
            className="absolute left-5 top-5 z-10 flex w-fit cursor-pointer flex-row justify-end rounded-full bg-core-gray-100 p-1.5"
            onClick={() => setModalState('login_required')}
          >
            <ArrowLeftIcon color="core-gray-700" size={18} />
          </div>
        </When>
        {!shouldShowMovieSelect && (
          <div
            className="absolute right-5 top-5 z-10 flex w-fit cursor-pointer flex-row justify-end rounded-full bg-core-gray-100 p-1.5"
            onClick={closePromoModal}
          >
            <CloseIcon color="core-gray-700" size={18} />
          </div>
        )}
        <div className="w-full p-2 sm:px-4">
          <When condition={modalState === 'loading'}>
            <CircleBusyIndicator className="mx-auto w-fit" variant="light" size={56} />
          </When>

          <When condition={modalState === 'intro'}>
            <TicketPromoModalIntro
              projectTitle={theatricalRelease.title as string}
              shouldShowCinemark={query?.theater === 'cinemark'}
              companyName={companyName}
              setEmployeeNumber={setEmployeeFreeTicketNumber}
              closePromoModal={closePromoModal}
              movies={movies}
              setSelectedMovie={setSelectedMovie}
              shouldShowMovieSelect={shouldShowMovieSelect}
            />
          </When>

          <When condition={modalState === 'login_required'}>
            <div>
              <HeadingSM weight="bold">{t('requestFreeTicketTitle', 'Request a Free Ticket')}</HeadingSM>
              <ParagraphMD className="mx-auto mt-2 max-w-[340px]" color="core-gray-700">
                {t(
                  'signUpToRequestFreeTicket',
                  'Sign up for a free Angel Studios account or login to request a free ticket.',
                )}
              </ParagraphMD>
              <div className="mx-auto mt-6 max-w-[290px]">
                <HideEmailSignInExperiment
                  isLATAM={isLATAM}
                  loginUrl={loginUrl}
                  trackLoginStarted={() => trackLoginStarted('tickets-promo-claim-free-ticket')}
                />
                <BuyTicketsLink
                  trackClick={trackClick}
                  ticketUrl={ticketUrl}
                  text={ticketButtonText}
                  theatricalReleaseRegion={theatricalReleaseRegion}
                />
              </div>
            </div>
          </When>
          <When condition={modalState === 'whatsapp_signup'}>
            <WhatsAppSignUp
              projectSlug={theatricalRelease.projectSlug}
              ticketUrl={ticketUrl}
              trackClick={trackClick}
              ticketButtonText={ticketButtonText}
              countryCode={((query?.region as string) ?? ''?.toUpperCase() ?? 'US') as CountryCode}
              locale={locale ?? 'en'}
              onPhoneVerified={() => {
                refetchUser().then(() => {
                  setWhatsAppVerified(true)
                  setModalState('select_reasons')
                })
              }}
            />
            {/*  so after verify code: change modelState to select_reasons  */}
          </When>
          <When condition={['select_reasons', 'otp_check'].includes(modalState)}>
            <div>
              <div className="flex w-full flex-row items-center justify-center">
                <div className="shrink-0">
                  <Avatar hideDefaultImage={true} size={80} />
                </div>
                <div className="ml-4 h-fit max-w-[93%] text-left">
                  <HeadingXS weight="bold" className="max-h-16 overflow-hidden text-ellipsis">
                    {getUserDisplayName(user)}
                  </HeadingXS>
                  <ParagraphMD color="core-gray-700" className="max-h-7 overflow-hidden text-ellipsis">
                    {user?.email}
                  </ParagraphMD>
                </div>
              </div>
              {modalState === 'select_reasons' && (
                <MultiFreeTickets
                  handleTicketCountChange={handleTicketCountChange}
                  ticketCount={ticketCount}
                  maxTickets={isCompanyFreeTicketFlow ? 4 : maxTickets}
                />
              )}
              <div className="mx-auto mt-6 px-2 sm:px-0">
                {modalState === 'select_reasons' && (
                  <>
                    <CheckBox
                      uncheckedClassName="!border-black rounded-none border-[3px] self-start mt-3 sm:mt-0 sm:self-center"
                      color="black"
                      checkedClassName="!bg-white border-[3px] flex items-center rounded-none !border-black self-start mt-3 sm:mt-0 sm:self-center"
                      id="accept-promise"
                      checked={promiseChecked}
                      onChange={(e) => setPromiseChecked(e.target.checked)}
                      labelClassName="flex items-center cursor-pointer ml-2 py-2"
                    >
                      <ParagraphMD className="text-left " weight="normal" color="black">
                        <Translate t={t} i18nKey="iPromiseIWillUseThisTicket">
                          I promise I will use this ticket.
                        </Translate>
                      </ParagraphMD>
                    </CheckBox>
                    <div className="cursor-pointer">
                      <CheckBox
                        uncheckedClassName="!border-black rounded-none border-[3px] sm:mt-0 self-start mt-3 sm:mt-0 sm:self-center"
                        color="black"
                        checkedClassName="!bg-white border-[3px] flex items-center rounded-none !border-black self-start mt-3 sm:mt-0 sm:self-center"
                        id="accept-terms"
                        checked={hasAcceptedTerms}
                        onChange={(e) => setHasAcceptedTerms(e.target.checked)}
                        labelClassName="flex items-center cursor-pointer ml-2 py-2"
                      >
                        <ParagraphMD className="text-left" weight="normal" color="black">
                          <Translate t={t} i18nKey="acceptTerms">
                            I accept the{' '}
                            <ExternalLink
                              className="font-semibold underline"
                              href={`/legal/${theatricalRelease.theatricalSlug}/free-ticket-terms`}
                            >
                              Terms and Conditions
                            </ExternalLink>
                          </Translate>
                        </ParagraphMD>
                      </CheckBox>
                    </div>
                    {isCompanyFreeTicketFlow && (
                      <div>
                        <ParagraphLG weight="semibold" className="mt-4 text-left">
                          <Translate t={t} i18nKey="requestFreeTicketNumberLabel">
                            Sponsor code or employee number:
                          </Translate>
                        </ParagraphLG>
                        <input
                          placeholder="1234"
                          className="mt-2 w-full rounded-lg border border-core-gray-200 bg-core-gray-100 p-3.5 font-medium"
                          type="text"
                          value={employeeNumber}
                          onChange={(e) => setEmployeeNumber(e.target.value)}
                        />
                      </div>
                    )}
                  </>
                )}
                {modalState === 'otp_check' && (
                  <div className="-ml-1 mt-8 sm:mx-2">
                    <ParagraphMD weight="normal" className="w-full break-normal">
                      {t(
                        'checkEmailOTPWAP',
                        'Please check your email for your verification code. Please allow up to 10 minutes.',
                      )}
                      <span className="ml-2 cursor-pointer font-semibold underline" onClick={sendOTP}>
                        {t('resend', 'Resend')}
                      </span>
                    </ParagraphMD>
                    <div className="mx-auto -mb-7 mt-3 flex w-fit flex-col">
                      <AngelVerificationInput theme="angel-wide" onChange={setOtp} />
                    </div>
                  </div>
                )}
              </div>
              <div className="mx-auto mt-7 flex w-fit flex-col justify-items-stretch">
                <Button
                  variant="black"
                  disabled={
                    waitingForRequiredOPT ||
                    !promiseChecked ||
                    !hasAcceptedTerms ||
                    isProcessing ||
                    (isCompanyFreeTicketFlow && employeeNumber.length < 2)
                  }
                  onClick={handleClaimTickets}
                >
                  {isProcessing && <LoadingSpinnerIcon className="h-7" />}
                  {!isProcessing && (
                    <ParagraphMD className="mx-auto" weight="bold">
                      {modalState === 'otp_check' && t('verifyOTPButton', 'Verify')}
                      {modalState === 'select_reasons' && t('requestFreeTicket', 'Request Free Ticket')}
                    </ParagraphMD>
                  )}
                </Button>
                <BuyTicketsLink
                  trackClick={trackClick}
                  ticketUrl={ticketUrl}
                  text={ticketButtonText}
                  theatricalReleaseRegion={theatricalReleaseRegion}
                />
              </div>
            </div>
          </When>

          <When condition={modalState === 'email_sent'}>
            <PromoSuccess
              theaterSlug={theatricalSlug}
              whatsAppVerified={!!whatsAppVerified}
              isOnlineRedeemable={isOnlineRedeemable}
              ticketUrl={ticketUrl ?? ''}
              trackClick={trackClick}
            />
          </When>
          <When condition={modalState === 'instant_promo_code'}>
            <InstantPromoCodeV2
              viaWhatsApp={whatsAppVerified ?? false}
              setModalState={setModalState}
              showTimeLink={claimTicketResponse?.addUserToWaitlist?.showtimeLink}
              voucherCodes={claimTicketResponse?.addUserToWaitlist?.voucherCodes}
            />
          </When>

          <When condition={modalState === 'duplicate_error'}>
            <div>
              <HeadingSM weight="bold">{t('duplicateRequestTitle', 'Ticket Requested')}</HeadingSM>
              <ParagraphMD className="mt-4" weight="normal" color="core-gray-700">
                <Translate t={t} i18nKey="duplicateRequestErrorMessage" values={{ emailAddress }}>
                  It looks like you already requested a ticket! Please check your email inbox for:
                  <span className="block py-2 font-semibold text-core-gray-950">{{ emailAddress }}</span>
                  If you recently requested your ticket, please allow up to 10 minutes for the email to arrive.
                </Translate>
              </ParagraphMD>
              <div className="mx-auto w-full max-w-[250px]">
                <BuyTicketsLink
                  trackClick={trackClick}
                  ticketUrl={ticketUrl}
                  text={ticketButtonText}
                  theatricalReleaseRegion={theatricalReleaseRegion}
                />
              </div>
            </div>
          </When>

          <When condition={modalState === 'error'}>
            <div>
              <HeadingSM weight="bold">{t('errorTitle', 'Oh No!')}</HeadingSM>
              <ParagraphMD className="mb-8 mt-4" weight="normal" color="core-gray-700">
                <Translate t={t} i18nKey="errorMessage">
                  Sorry, something unexpected went wrong!
                  <br />
                  Please try again later.
                </Translate>
              </ParagraphMD>
              <div className="mx-auto w-full max-w-[250px]">
                <BuyTicketsLink
                  trackClick={trackClick}
                  ticketUrl={ticketUrl}
                  text={ticketButtonText}
                  theatricalReleaseRegion={theatricalReleaseRegion}
                />
              </div>
            </div>
          </When>
        </div>
      </div>
    </Modal>
  )
}

function noop() {
  // do nothing
}
