import React, { FC } from 'react'
import classNames from 'classnames'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { Image } from '@/atoms/Image'
import { CaptionSM, Footer, Text, TitleSM, TitleXS } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { FlexibleBookingToolTip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ToolTips/FlexibleBookingToolTip'
import { SpecificTimesToolTip } from '../ToolTips/SpecificTimesToolTip'

interface NoFeesProps {
  className?: string
  theatricalSlug?: string
  code: string
}

const getBannerBackground = (theatricalSlug?: string) => {
  switch (theatricalSlug) {
    case 'bonhoeffer':
      return 'bg-bonhoeffer-dark'
    case 'brave-the-dark':
      return 'bg-black'
    case 'homestead':
      return 'bg-[#06050A]'
    default:
      return 'bg-black'
  }
}

const getBannerImage = (theatricalSlug?: string) => {
  switch (theatricalSlug) {
    case 'bonhoeffer':
      return {
        src: '/v1731355155/bonhoeffer/bonhoeffer-new-banner-img.jpg',
        alt: 'Bonhoeffer',
      }
    case 'brave-the-dark':
      return {
        src: '/v1738013795/brave-the-dark/brave-the-dark---official-key-art---digital---16x9---textless-web.webp',
        alt: 'Brave the Dark',
      }
    case 'homestead':
      return {
        src: 'v1731111571/Homestead/homestead-banner-bg-fade.webp',
        alt: 'Homestead',
      }
    default:
      return null
  }
}
const NoFeesBanner: FC<NoFeesProps> = ({ theatricalSlug, className, code }) => {
  const { t } = useTranslate('tickets')
  const bgColor = getBannerBackground(theatricalSlug)
  const bannerImage = getBannerImage(theatricalSlug)
  return (
    <div className="relative ">
      <div className="relative  z-[20] min-h-[120px] rounded-xl">
        <div
          className={classNames(
            'relative bg-cover bg-left bg-no-repeat @sm:max-h-[160px] @2xl:max-h-[120px]',
            bgColor,
            '@container flex items-center justify-between pr-3 h-full',
            className,
          )}
        >
          <FlexColumn className="h-full pl-4 @sm:px-6" style={{ zIndex: 50 }}>
            <FlexRow className="gap-1.5">
              <div className="absolute top-1/2 mx-auto w-fit -translate-y-1/2" style={{ zIndex: 60 }}>
                <TitleSM className="relative" weight="bold" color="white">
                  <Translate i18nKey="noOnlineFeesNoHassle" t={t}>
                    No Online Fees. <span className="hidden @sm:inline-block">No Hassle.</span>
                  </Translate>
                </TitleSM>
                <div style={{ zIndex: 70 }}>
                  <SpecificTimesToolTip buttonClassName="!px-0">
                    <TitleXS color="white" className="hidden @md:block">
                      {t('onSelectShowtimes', 'On Select Showtimes')}
                    </TitleXS>
                    <CaptionSM color="white" className="@md:hidden">
                      {t('onSelectShowtimes', 'On Select Showtimes')}
                    </CaptionSM>
                  </SpecificTimesToolTip>
                  <FlexibleBookingToolTip
                    className="mt-1"
                    buttonClassName="bg-white/20 border-none text-white focus-visible:outline-none focus-visible:ring-offset-transparent focus-visible:ring-transparent backdrop-blur-[24px] !backdrop-filter"
                  />
                </div>
              </div>
            </FlexRow>
          </FlexColumn>

          <FlexColumn className="w-[40%] items-center py-[14px] @sm:w-[250px] @4xl:py-0">
            <Text className="photon-eyebrow-sm @lg:!photon-eyebrow-md text-white" weight="semibold">
              {t('usePromoCode', 'Use Promo Code')}
            </Text>
            <TitleSM weight="bold" className="my-1 bg-[#F2A31E] px-3 py-2">
              {code}
            </TitleSM>
            <Text className="photon-eyebrow-sm @lg:!photon-eyebrow-md text-white" weight="semibold">
              {t('atCheckout', 'At Checkout')}
            </Text>
            <Footer color="core-gray-400" italic className="text-center">
              {t('angelPartneringTheaters', 'Available for Angel Partnering Theaters.')}
            </Footer>
          </FlexColumn>

          <div
            className={classNames(
              'absolute right-[133px] top-0 z-10 hidden h-full w-[120px] bg-gradient-to-l from-transparent',
              `to-black`,
              'to-35% @2xl:block',
            )}
          />
          <div className="z-1 mr-0 hidden overflow-hidden rounded-r-xl @2xl:block lg:-mr-3">
            {theatricalSlug === 'bonhoeffer' && (
              <div className="absolute right-[133px] top-0 z-10 hidden h-full w-[120px] bg-gradient-to-l from-transparent to-bonhoeffer-dark to-35% @2xl:block" />
            )}
            {theatricalSlug === 'homestead' && (
              <div className="absolute right-[133px] top-0 z-10 hidden h-full w-[120px] bg-gradient-to-l from-transparent to-[#06050A] to-100% @2xl:block" />
            )}
            {bannerImage && <Image src={bannerImage.src} alt={bannerImage.alt} width={200} height={200} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoFeesBanner
