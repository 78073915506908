import { useEffect, useState } from 'react'
import { logger } from '@/utils/logging'

interface CacheEntry {
  multiplier: number | null
  timestamp: number
}

// Module-level cache and pending promise
let cache: CacheEntry | null = null
let pendingPromise: Promise<number | null> | null = null
const CACHE_DURATION = 1000 * 60 * 5 // 5 minutes

const fetchMultiplier = async () => {
  // If there's already a request in flight, return that promise
  if (pendingPromise) return pendingPromise

  // If cache is valid, return cached value
  if (cache && Date.now() - cache.timestamp < CACHE_DURATION) {
    return cache.multiplier
  }

  // Create new request
  pendingPromise = fetch('https://theatrical.angelstudios.com/api/v1/open/weeks-until/brave-the-dark')
    .then((res) => {
      if (!res.ok) {
        const error = new Error(`weeks-until failed with status ${res.status}`)
        error.name = 'WeeksUntilStatusError'
        logger().error('weeks-until failed with status', res.status)
        throw error
      }
      return res.json()
    })
    .then((data) => {
      if (!data?.weeks_until_release) {
        const error = new Error('weeks-until missing data')
        error.name = 'WeeksUntilDataError'
        logger().error('weeks-until missing data', data)
        throw error
      }
      const value = data.weeks_until_release
      cache = { multiplier: value, timestamp: Date.now() }
      return value
    })
    .catch((error) => {
      error.name = 'WeeksUntilFetchError'
      logger().error('weeks-until fetch failed', { cache, error })
      return null
    })
    .finally(() => {
      pendingPromise = null
    })

  return pendingPromise
}

export function useMultiplier() {
  const [multiplier, setMultiplier] = useState<number | null>(() => cache?.multiplier ?? null)

  useEffect(() => {
    fetchMultiplier().then(setMultiplier)
  }, [])

  return multiplier
}
